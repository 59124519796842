'use strict'

import config from '../symbols.json'
import data from '@symbo.ls/temp-db/byld'
import { create } from 'smbls'

import { FastClick } from 'fastclick'
import { isDevelopment } from '@domql/env'

const App = {
  props: {
    theme: 'document',
    overflow: 'hidden visible',
    width: '100%',
    height: '100%',
    minHeight: '100%'
  },

  on: {
    scroll: (ev, el, s, ctx) => {
      const { MEDIA } = ctx.designSystem
      const { matches } = window.matchMedia(MEDIA.tabletM)
      if (matches) {
        s.update({ scrollTop: el.node.scrollTop })
      }
    }
  }
}

create(App, {
  key: config.key,
  designSystem: data.DESIGN_SYSTEM,
  state: data.STATE,
  components: data.COMPONENTS,
  pages: data.PAGES,
  snippets: data.SNIPPETS,
  editor: {
    remote: false,
    async: false,
    inspect: isDevelopment(),
    liveSync: isDevelopment(),
    endpoint: window.location.host.includes('dev.localhost') ? 'http://localhost:8080/' : 'api.symbols.dev',
    serviceRoute: 'state',
    env: process.env.NODE_ENV
  }
}).then(() => {
  FastClick.attach(document.body)
})
